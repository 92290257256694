

import {useState, useEffect} from 'react'
import Calendar from 'react-calendar';
import API from '../API';
import './Calendar.css';
import {Day,DayDisplay, formatDate} from './DayDisplay'
import "./log.css"
import { format } from 'path';


// react component that represents
// the journal page
export default function Route() {

    let upperbound = null;
    let lowerbound = null;
    // lowerbound.setDate(lowerbound.getDate() - 7)

    const [days, setDays] = useState([])

    //sets initial lower bound for dates
    // to query via the api
    // should initially query the past week
    const [dates, setDates] = useState<any>([lowerbound, upperbound])
    const [show, setShow] = useState(false)

    function formatApiDate(time:any) {
        
        // formates js dates so they are in a format the api will understand
        let date = `${time.getMonth() + 1}-${time.getDate()}-${time.getFullYear(0)}`

        return date
    }


    //this section is deprecated but was used for 
    //selecting days in the range selected by the user

    //queries for date data everytime the
    //dates selected by the user change 
    useEffect(function(){
        (async function(){
            let api = new API("post","days")
            let data:any = null;
            if (dates[0] && dates[1]){
                data = await api.query({
                    upperbound:formatApiDate(dates[1]), 
                    lowerbound:formatApiDate(dates[0])
                })
            } else {
                data = await api.query()
            }
            setDays(data)
        })()
    },[dates])

    // useEffect(function() {
    //     (async function(){
    //         let api = new API("post","days")
    //         let data:any = await api.query()
    //         setDays(data)
    //     }
    //     )()
    // },[])

    // displays the journal page
    return (
        <div className = "day-container flex-column">
        {/* 
        // @ts-ignore */}
        {(dates.length >= 2 && dates[0] != null && dates[1] != null)?
            <div className = "flex-column" style = {{maxWidth:"100%"}}>
                <h1 className="major" style={{padding:"3rem 0 0 0", textAlign:"center",maxWidth:"100%"}}>
                    {`${formatDate(dates[0])} - ${formatDate(dates[1])}`}
                </h1>
            </div>
            :
            <div className = "flex-column" style = {{maxWidth:"100%"}}>
                <h1 className="major" style={{padding:"3rem 0 0 0", textAlign:"center",maxWidth:"100%"}}>
                    Last 7 Days Logged
                </h1>
            </div>
        }
        {
            show? 
            <div id = "calendar-container">
                <Calendar onChange={(data) => {setDates(data);setShow(false)}} value={dates} selectRange={true} />
            </div>
            :
            <p onClick = {() => setShow(true)} className = "calendar-button-edit"> 
                Edit Date Range
            </p>

        }
        {days.length == 0 &&
        <h3 id ="main" style={{color:"red","textAlign":"center"}}>
        No Data Found On Selected Range
        </h3>
        }
        
        
        {days.map(
            (day:any) => (<DayDisplay {...day}  key = {day.date} />)
        )}
        </div>
    );

}